



























import { useContactData, useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { AddressDataDto } from "@interface/ContactDataDto.interface";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

type AddressType = "bill" | "ship";

@Component
export default class SelectContactAddress extends Vue {
  useLocalFilter = useLocalFilter;

  @Prop({ required: true, type: String, default: "" })
  value!: string;

  @Prop({ required: false, type: String, default: "bill" })
  addressType!: AddressType;

  @Prop({ required: false, type: Array, default: [] })
  addresses!: AddressDataDto[];

  @Prop({ required: false, type: Boolean, default: false })
  disabled!: boolean;

  options: Option<AddressDataDto>[] = [];

  created(): void {
    this.initOptions(this.addresses);
  }

  get isBill(): boolean {
    return this.addressType === "bill";
  }

  get isShip(): boolean {
    return this.addressType === "ship";
  }

  @Watch("addresses")
  onChangeAddresses(newValue: AddressDataDto[]): void {
    this.initOptions(newValue);
  }

  initOptions(addresses: Array<AddressDataDto>): void {
    const { toShipToAddressOptions, toBillToAddressOptions } = useContactData();
    if (this.isBill) {
      this.options = toBillToAddressOptions(addresses);
    } else if (this.isShip) {
      this.options = toShipToAddressOptions(addresses);
    } else {
      this.options = [];
    }
  }

  emits(e: string): void {
    this.$emit("input", e);
    this.$emit("change", e);
  }
}
